var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center"},[_c('h4',[_vm._v(_vm._s(_vm.stockAudit.company.short_name)+" | "+_vm._s(_vm.stockAudit.name)+" |")]),_c('small',{staticClass:"ml-2"},[_vm._v(" Iniciado em "+_vm._s(_vm.$format.dateTimeBr(_vm.stockAudit.started_at))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"d-flex justify-md-end flex-column flex-md-row",attrs:{"cols":"12","md":"8"}},[(_vm.$acl.can('updateStockViaAudit'))?_c('v-btn',{staticClass:"mr-md-4 order-3 order-md-0 ma-md-0 my-2",attrs:{"outlined":"","color":"primary","disabled":_vm.isClosed},on:{"click":function($event){return _vm.handleUpdateStockFromStockAudit()}}},[_c('v-icon',[_vm._v("mdi-autorenew")]),_vm._v(" Atualizar Tudo ")],1):_vm._e(),(_vm.$acl.can('stockAuditAddProduct'))?_c('v-btn',{staticClass:"order-1 order-md-2 ma-md-0 my-2",attrs:{"color":"primary","disabled":_vm.isClosed},on:{"click":function($event){return _vm.openStoreStockAuditProductForm()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Adicionar Produto ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.stock_audit_products.data,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.count))+" ")]}},{key:"item.stock_at_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.stock_at_start))+" ")]}},{key:"item.difference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.count - item.stock_at_start))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusData[item.status].color,"small":""}},[_vm._v(" "+_vm._s(_vm.statusData[item.status].text)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.$acl.can('updateStockViaAudit'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","disabled":item.status == 'updated'},on:{"click":function($event){$event.stopPropagation();return _vm.handleUpdateStockFromStockAuditProduct(item.id)}}},[_c('v-icon',[_vm._v("mdi-autorenew")])],1):_vm._e(),(_vm.$acl.can('stockAuditCreateEdit'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":item.status == 'updated'},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProduct(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.stock_audit_products},on:{"click":function($event){return _vm.pagination($event)}}})],1)],1),_c('StoreStockAuditProductForm',{ref:"StoreStockAuditProductForm",on:{"store":function($event){return _vm.selectStockAuditProduct()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }